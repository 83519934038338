import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { ReactComponent as ArrowDown } from "@images/arrow-down.svg";

export default function AccordionComponent({ title, items }) {
  const [expanded, setExpanded] = React.useState("accordion");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderDetailList = useMemo(() => {
    return items?.map((item, index) => {
      return (
        <div key={index}>
          <h3 className="item_label">{item.label?.text}</h3>
          <p className="item_value">{item.value?.text}</p>
        </div>
      );
    });
  }, [items]);

  return (
    <div c>
      <Accordion
        expanded={expanded === "accordion"}
        onChange={handleChange("accordion")}
        className="accordion"
      >
        <AccordionSummary
          aria-controls="accordionbh-content"
          id="accordionbh-header"
          expandIcon={<ArrowDown />}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails>
          {<div className="accordion-items-list">{renderDetailList}</div>}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
