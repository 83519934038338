import React, { useEffect, useCallback, useMemo } from "react";
import { Power3, gsap } from "gsap/all";
import Observer from "react-intersection-observer";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

export const Chart = ({ slice }) => {
  const imageUrl = slice.primary?.chart?.url;
  const cardTitle = slice.primary?.card_title?.text;
  const statTitle = slice.primary?.stat_title?.text;
  const title = slice.primary?.title?.text;
  const subtitle = slice.primary?.subtitle?.text;
  const btn_label = slice.primary?.btn_label?.text;
  const link = slice.primary?.link?.text;
  const statItems = slice?.items;

  useEffect(() => {
    initGSAP();
  }, []);

  const handleContentVisibility = useCallback((inView, entry) => {
    if (inView) {
      animateContent();
    }
  }, []);

  const onClick = useCallback(() => window.open(link, "_blank"), [link]);

  const renderChartStats = useMemo(() => {
    return statItems?.map((item) => {
      return (
        <>
          <div className="chart_stat">
            <div className="chart_stat_label">{item.label.text}</div>
            <div className="chart_stat_value">{item.value.text}</div>
          </div>
          <Divider className="divider" light />
        </>
      );
    });
  }, [statItems?.length]);
  return (
    <Observer
      threshold="0.1"
      onChange={handleContentVisibility}
      className="wrapper container indents"
    >
      <div className="chart">
        <div className="chart_image_wrap">
          <div className="chart_title">{title}</div>
          <div className="">
            <img className="chart_image" src={imageUrl}></img>
          </div>
        </div>
        <div className="chart_stats">
          <div className="chart_stats_wrap">
            <div className="chart_stats_title">{statTitle}</div>
            <div className="chart_stats_list">
              <Divider className="divider" light />
              {renderChartStats}
            </div>
          </div>
          <div class="chart_stats_card">
            <div>
              <div className="card_title">{cardTitle}</div>
              <div className="card_subtitle">{subtitle}</div>
            </div>
            <div>
              <Button
                variant="contained"
                className="card_btn"
                onClick={onClick}
              >
                {btn_label}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Observer>
  );

  function initGSAP() {
    gsap.set([".chart"], {
      opacity: 0,
      y: 100,
    });
  }

  function animateContent() {
    gsap.to(".chart", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.4,
    });
  }
};

export default Chart;
