import React, { useEffect, useCallback, useMemo } from "react";
import { Power3, gsap } from "gsap/all";
import Observer from "react-intersection-observer";
import AccordionText from "@components/Accordion/AccordionText";
import Divider from "@material-ui/core/Divider";

export const TextDescriptionComponent = ({ slice }) => {
  const title = slice.primary?.title?.text;
  // const description = slice?.primary?.description?.text;
  const descriptionMulti = slice?.primary?.description?.raw;

  const text = useMemo(
    () =>
      descriptionMulti?.map((item) => ({
        text: item.text,
        strong: item?.spans?.find((span) => span.type === "strong"),
      })),
    [descriptionMulti]
  );

  useEffect(() => {
    initGSAP();
  }, []);

  const handleContentVisibility = useCallback(
    (inView, entry) => {
      if (!inView) return;
      animateContent();
    },
    [animateContent]
  );

  return (
    <Observer
      threshold="0.1"
      onChange={handleContentVisibility}
      className="wrapper container"
    >
      <div className="about indents">
        <AccordionText title={title} text={text} />
        <Divider className="divider" light />
      </div>
    </Observer>
  );

  function initGSAP() {
    gsap.set([".accordion"], {
      opacity: 0,
      y: 100,
      ease: Power3.easeOut,
    });
  }

  function animateContent() {
    gsap.to(".accordion", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.2,
    });
  }
};

export default TextDescriptionComponent;
