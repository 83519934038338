import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Power3, gsap } from "gsap/all";
import Observer from "react-intersection-observer";
import { set } from "react-ga";

export const DetailsList = ({ slice }) => {
  const { total_label, total_value, ...headers } = slice.primary;
  const dataForTable = slice.items;
  useEffect(() => {
    initGSAP();
  }, [initGSAP]);

  const handleContentVisibility = useCallback(
    (inView, entry) => {
      if (inView) {
        animateContent();
      }
    },
    [animateContent]
  );

  const renderHeaders = useMemo(() => {
    const headersKey = Object.keys(headers);
    return headersKey?.filter((key) => headers[key].text).map((key, index) => {
      return (
        <th align="left" key={index}>
          {headers[key].text}
        </th>
      );
    });
  }, [headers]);

  const renderData = useMemo(() => {
    return dataForTable?.map((data, index) => {
      return (
        <tr align="left" key={index}>
          <td align="left" className="td_portfolio">
            {data.column1_data.text}
          </td>
          <td align="left" className="td_description">
            {data.column2_data.text}
          </td>
          <td align="left" className="td_strategy" style={{ width: "150px" }}>
            {data.column3_data.text}
          </td>
          <td align="left" className="td_weight">
            {data.column4_data.text}
          </td>
          {/* <td align="left" className="td_info">
            <a className="" href={data.link.url} target={data.link.target}>
              {data.column5_data.text}
            </a>
          </td> */}
        </tr>
      );
    });
  }, [dataForTable]);
  return (
    <Observer
      threshold="0.1"
      onChange={handleContentVisibility}
      className="wrapper container indents table_assets"
    >
      <div>
        <h2>Underlying Assets</h2>
        <table>
          <thead>
            <tr>{renderHeaders}</tr>
          </thead>
          <tbody>{renderData}</tbody>
          <tfoot>
            <tr className="tr_total">
              <td className="td_title">{total_label?.text}</td>
              <td align="left" colSpan="2"></td>
              <td align="left" colSpan="2">
                {total_value?.text}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </Observer>
  );

  function initGSAP() {
    gsap.set(["table"], {
      opacity: 0,
      y: 100,
    });
  }

  function animateContent() {
    gsap.to("table", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.4,
    });
  }
};

export default DetailsList;
