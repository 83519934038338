import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Power3, gsap } from "gsap/all";
import Observer from "react-intersection-observer";
import { set } from "react-ga";

export const DetailsList = ({ slice }) => {
  useEffect(() => {
    initGSAP();
  }, [initGSAP]);

  const handleContentVisibility = useCallback(
    (inView, entry) => {
      if (inView) {
        animateContent();
      }
    },
    [animateContent]
  );
  const headers = [
    "Year",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
    "3IQ DAP",
    "Bitcoin",
    "S&P 500",
  ];
  const data = [
    {
      year: 2024,
      values: [
        "0.47%",
        "17.04%",
        "4.49%",
        "-7.95%",
        "2.64%",
        "-5.36%",
        "-2.24",
        "-",
        "-",
        "-",
        "-",
        "-",
        //
        "7.4%",
        "18.4%",
        "38.4%",
      ],
    },
    {
      year: 2023,
      values: [
        "11.74%",
        "3.67%",
        "5.14%",
        "1.54%",
        "-2.42%",
        "2.51%",
        "-0.56%",
        "-2.14%",
        "-1.17%",
        "7.38%",
        "4.3%",
        "5.93%",
        //

        "41.13%",
        "157.04%",
        "24.23%",
      ],
    },
    {
      year: 2022,
      values: [
        "-1.41%",
        "1.02%",
        "6.19%",
        "-6.34%",
        "-2.34%",
        "0.16%",
        "3.37%",
        "-0.77",
        "-0.85%",
        "2.45%",
        "0.37%",
        "-4.23%",
        //
        "-2.96%",
        "-64.75%",
        "-19.44%",
      ],
    },
  ];

  return (
    <Observer
      threshold="0.1"
      onChange={handleContentVisibility}
      className="wrapper container indents"
    >
      <table className="table_static">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.year}</td>
              {row.values.map((value, idx) => (
                <td key={idx} className={value.includes("-") ? "red" : ""}>
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Observer>
  );

  function initGSAP() {
    gsap.set([".table_static"], {
      opacity: 0,
      y: 100,
    });
  }

  function animateContent() {
    gsap.to(".table_static", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.4,
    });
  }
};

export default DetailsList;
