import React, { useEffect, useCallback } from "react";
import $ from "jquery";

import { TweenMax as TM, Power2 } from "gsap/all";
import Observer from "react-intersection-observer";

export const HeroComponent3Iq = ({ slice }) => {
  const title = slice.primary?.title?.text;
  const subtitle = slice.primary?.subtitle?.text;
  const prependLogo = slice.primary?.prepend_logo?.url;
  const backgroundImg = slice.primary?.background_image?.url;

  useEffect(() => {
    initGSAP();
  }, [initGSAP]);

  // const handleContentVisibility = useCallback(
  //   (inView, entry) => {
  //     if (inView) {
  //       animateContent();
  //     }
  //   },
  //   [animateContent]
  // );

  return (
    <Observer
      threshold="0.1"
      // onChange={handleContentVisibility}
      className="wrapper"
    >
      <div
        className="hero"
        style={{
          backgroundImage: "url('" + backgroundImg + "')",
        }}
      >
        <div className="hero_content container">
          <img className="hero_logo--prepend" src={prependLogo}></img>
          <div className="hero_text">
            <div className="hero_title">{title}</div>
            <div className="hero_subtitle">{subtitle}</div>
          </div>
        </div>
      </div>
    </Observer>
  );

  function initGSAP() {
    let titleText = document.getElementsByClassName("hero_text");
    let descText = document.getElementsByClassName("hero_subtitle");
    let prependLogo = document.getElementsByClassName("hero_logo--prepend");

    TM.fromTo(
      prependLogo,
      1,
      { transform: "translateY(50%)", opacity: 0 },
      {
        transform: "translateY(0%)",
        opacity: 1,
        ease: Power2.easeOut,
        delay: 0.7,
      }
    );

    TM.fromTo(
      titleText,
      1,
      { transform: "translateY(50%)", opacity: 0 },
      {
        transform: "translateY(0%)",
        opacity: 1,
        ease: Power2.easeOut,
        delay: 0.8,
      }
    );

    TM.fromTo(
      descText,
      1,
      { transform: "translateY(50%)", opacity: 0 },
      {
        transform: "translateY(0%)",
        opacity: 1,
        ease: Power2.easeOut,
        delay: 0.9,
      }
    );
  }

  // function animateContent() {
  //   gsap.to(".hero_title", {
  //     opacity: 1,
  //     y: 0,
  //     ease: Power3.easeOut,
  //     delay: 0.4,
  //   });
  //   gsap.to(".hero_subtitle", {
  //     opacity: 1,
  //     y: 0,
  //     ease: Power3.easeOut,
  //     delay: 0.4,
  //   });
  // }
};

export default HeroComponent3Iq;
