import React, { useState, useEffect, useCallback, useMemo } from "react";
import $ from "jquery";
import { gsap, Power2 } from "gsap/all";
import logo from "@images/logo/logo.png";
import { isTablet } from "react-device-detect";
import "./ProductPrompt.scss";
import Button from "@material-ui/core/Button";
import browserStorage from "@utils/browserStorage";
import { navigate } from "gatsby";

const ProductPrompt = ({ item, onClose, slice }) => {
  const [residence, setResidence] = useState(""); // State for residence
  const [investor, setInvestor] = useState(""); // State for investor

  const title = slice.primary?.title?.text;
  const subtitle = slice.primary?.subtitle?.text;

  const question1_label = slice.primary?.question1_label?.text;
  const question1_options = slice.primary?.question1_options?.raw;

  const question2_label = slice.primary?.question2_label?.text;
  const question2_options = slice.primary?.question2_options?.raw;

  const terms = slice.primary?.terms?.text;
  const list_title = slice.primary?.list_title?.text;
  const list_options = slice.primary?.list_options?.raw;

  useEffect(() => {
    showDetail();
    disableScroll();

    if (isTablet && window.innerWidth > window.innerHeight) {
      $(".productPrompt").css("position", "fixed");
    }

    $(".productPrompt .backBtn").on("click", () => hideDetail());

    $(".productPrompt .closeBtn").on("click", () => hideDetail());

    return () => {
      $(".productPrompt .backBtn").off("click", () => hideDetail());
      $(".productPrompt .closeBtn").off("click", () => hideDetail());
      enableScroll();
    };
  }, []);

  // Handlers for radio buttons
  const handleResidenceChange = useCallback(
    (event) => {
      setResidence(event.target.value);
    },
    [setResidence]
  );

  const handleInvestorChange = useCallback(
    (event) => {
      setInvestor(event.target.value);
    },
    [setInvestor]
  );

  const renderResidenceRadioOptions = useMemo(() => {
    return question1_options?.map((option, index) => (
      <label key={option.text + index}>
        <input
          type="radio"
          name={option.text}
          value={option.text}
          checked={residence === option.text}
          onChange={handleResidenceChange}
        />
        {option.text}
      </label>
    ));
  }, [question1_options, handleResidenceChange, residence]);

  const renderInvestorRadioOptions = useMemo(() => {
    return question2_options?.map((option, index) => (
      <label key={option.text + index}>
        <input
          type="radio"
          name={option.text}
          value={option.text}
          checked={investor === option.text}
          onChange={handleInvestorChange}
        />
        {option.text}
      </label>
    ));
  }, [investor,investor, handleInvestorChange, question2_options]);

  const onSubmit = useCallback(() => {
    const isReject =
      investor === question2_options[question2_options.length - 1].text;
    if (isReject || !residence || !investor) return onReject();
    browserStorage.set("residence", residence);
    browserStorage.set("investor", investor);
    hideDetail();
  }, [residence, investor, hideDetail, question2_options]);

  const onReject = useCallback(() => {
    browserStorage.remove("residence");
    browserStorage.remove("investor");
    hideDetail();
    navigate("/");
  }, [hideDetail, navigate]);

  return (
    <div className="modal-overlay">
      <div className="productPrompt">
        <div className="productPrompt_header">
          <img
            className="header__cont__firstCol_logo"
            alt={"logo smartwealth"}
            src={logo}
          ></img>
        </div>
        <div className="productPrompt_body">
          <h2>{title}</h2>
          <p>{subtitle}</p>

          <div className="questions">
            <p>{question1_label}</p>
            {renderResidenceRadioOptions}

            <p>{question2_label}</p>
            {renderInvestorRadioOptions}
          </div>

          <p>{list_title}</p>
          <ul className="list">
            {list_options?.map((option, index) => (
              <li key={index}>{option.text}</li>
            ))}
          </ul>

          <p className="terms">{terms}</p>

          <div className="buttons">
            <Button disableRipple={true} className="accept" onClick={onSubmit}>
              Accept and continue
            </Button>
            <Button disableRipple={true} className="reject" onClick={onReject}>
              Reject
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  function enableScroll() {
    $("html, body").css("overflow", "");
  }

  function disableScroll() {
    $("html, body").css("overflow", "hidden");
  }

  function showDetail() {
    $(".modal-overlay").fadeIn();
    gsap.to($(".productPrompt_body"), 0.4, {
      x: "0px",
      ease: Power2.easeOut,
      delay: 0.2,
    });
  }

  function hideDetail() {
    gsap.to($(".productPrompt_body"), 0.4, {
      x: "-100%",
      ease: Power2.easeOut,
      onComplete: () => {
        $(".modal-overlay").fadeOut();
        gsap.to($("header"), {
          y: "0%",
          opacity: 1,
          ease: Power2.easeOut,
          onComplete: () => {
            enableScroll();
            $("#fp-nav").fadeIn();
          },
        });
      },
    });
  }
};

export default ProductPrompt;
