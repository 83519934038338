import React, { useEffect, useCallback } from "react";
import { Power3, gsap } from "gsap/all";
import Observer from "react-intersection-observer";
import AccordionListItem from "@components/Accordion/AccordionListItem";
import Divider from "@material-ui/core/Divider";

export const DetailsList = ({ slice }) => {
  const title = slice.primary?.title?.text;
  const items = slice.items;
  useEffect(() => {
    initGSAP();
  }, []);

  const handleContentVisibility = useCallback((inView, entry) => {
    if (inView) {
      animateContent();
    }
  }, []);

  return (
    <Observer
      threshold="0.1"
      onChange={handleContentVisibility}
      className="wrapper container"
    >
      <div className="details indents">
        <AccordionListItem items={items} title={title}></AccordionListItem>
        <Divider className="divider" light />
      </div>
    </Observer>
  );

  function initGSAP() {
    gsap.set([".accordion"], {
      opacity: 0,
      y: 100,
    });
  }

  function animateContent() {
    gsap.to(".accordion", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.4,
    });
  }
};

export default DetailsList;
