function parseJson(json) {
  try {
    return JSON.parse(json);
  } catch (err) {
    return {};
  }
}

const browserStorage = () => {
  const get = (key) => {
    if (typeof window !== "undefined") {
      const data = parseJson(localStorage.getItem(key));
      return data || null;
    }
  };

  const set = (key, data) => {
    if (typeof window !== "undefined") {
      localStorage.setItem(key, JSON.stringify(data));
    }
  };

  const remove = (key) => {
    if (typeof window !== "undefined") {
      localStorage.removeItem(key);
    }
  };
  const setLanguage = (lang) => {
    set("lang", lang.split("-").shift());
  };

  return {
    get,
    set,
    setLanguage,
    remove,
  };
};

export default browserStorage();
