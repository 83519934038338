import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { ReactComponent as ArrowDown } from "@images/arrow-down.svg";
import "@components/Accordion/accordionStyles.scss";

export default function AccordionComponent({ children, title, defaultExpanded }) {
  const defaultName = defaultExpanded ? "accordion" : "";
  const [expanded, setExpanded] = React.useState(defaultName);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div c>
      <Accordion
        expanded={expanded === "accordion"}
        onChange={handleChange("accordion")}
        className="accordion"
      >
        <AccordionSummary
          aria-controls="accordionbh-content"
          id="accordionbh-header"
          expandIcon={<ArrowDown />}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}
